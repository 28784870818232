.errorContainer {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	background-color: white;
	margin: 0;
	padding: 0;
}

.errorCode {
	color: #202424;
	font-size: 45px;
	margin: 0;
	padding: 0;
	font-family: Cedora-Bold;
}

.errorDisplay {
	color: #88cdd5;
	font-size: 40px;
	margin: 0;
	padding: 0;
	font-family: Cedora-Regular;
}

.errorButton {
	width: 100%;
	background-color: #202424;
	height: 5vh;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3vh;
	border-radius: 5px;
	font-family: "Cedora-Bold";
	color: white;
	cursor: pointer;
}

.errorButton:hover {
	background-color: #060707;
}
