.dictionaryRow:hover {
	background-color: rgba(238, 238, 238, 0.836);
}

.dictionaryRowClickable {
	cursor: pointer;
}

.listKeyStyle {
	background-color: #cfcfcf63;
	padding: 2px;
	border-radius: 2px;
	font-weight: 600;
}

.addMenu .MuiPaper-root {
	box-shadow:
		0rem 0rem 3rem 1rem rgba(0, 0, 0, 0.1),
		0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05) !important;
}
