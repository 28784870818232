.cardGraphChoice {
	/* width: 45%; */
	margin-top: 2vh;
	cursor: pointer;
}

.dialogContentChartEditor {
	background-color: #f0f2f5 !important;
}

.fullHeight {
	min-height: 95%;
}
