.subMenuOpen {
	background-color: #f5f5f5;
}

.subMenuItem {
	cursor: pointer;
	background-color: #f5f5f5;
}

.selection:hover {
	cursor: pointer;
	background-color: #f5f5f5;
}

.paperDictMenu {
	box-shadow: none;
	background-color: transparent !important;
	border: 1px solid #3a3a3a38;
}
