.today-date {
	background-color: #e8212190 !important;
}

.between-date {
	background-color: #1a73e8 !important;
	border-radius: 0px !important;
	margin: 0px !important;
	color: white !important;
}

.second-selector-color {
	background-color: #1a73e891 !important;
	color: white !important;
}

.dayContainer > .flatpickr-day:nth-child(7n),
.dayContainer > .flatpickr-day:nth-child(7n-1) {
	background: #f0f0f062;
	border-radius: 0;
}

.flatpickr-day:hover {
	background: rgba(26, 115, 232, 0.28) !important;
	border: 1px solid transparent !important;
	border-radius: 150px !important;
}

.end-date {
	border-radius: 150px !important;
	border-top-right-radius: 1000% !important;
	border-bottom-right-radius: 1000% !important;
	/* border-bottom-right-radius: 0 !important; */
}

.start-date {
	border-radius: 150px !important;
	border-top-left-radius: 1000% !important;
	border-bottom-left-radius: 1000% !important;
}
